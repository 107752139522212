import React from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
  font?: "poppins" | "montserrat";
  style?: React.CSSProperties;
};

export const Paragraph: React.FunctionComponent<Props> = ({
  font = "poppins",
  children,
  className = "",
  style = {},
}) => (
  <p
    className={`text-md text-secondary mb-5 leading-relaxed ${
      font === "poppins" ? "font-poppins" : "font-montserrat"
    } ${className}`}
    style={style}
  >
    {children}
  </p>
);
