import React from "react";

import { AboutUs } from "./AboutUs";
import { FleetForYourCharter } from "./FleetForYourCharter";
import { FutureProjects } from "./FutureProjects";
import { HeroSection } from "./HeroSection";
import { OperatingLocations } from "./OperatingLocations";
import { PhotoGallery } from "./PhotoGallery";
import { Reviews } from "./Reviews";
import { Services } from "./Services";
import { TrustedClients } from "./TrustedClients";
import { AnnouncementModal } from "~/components/AnnouncementModal";
import { navigate } from "gatsby";

export const Home: React.FunctionComponent = () => (
  <div>
    <HeroSection />
    <TrustedClients />
    <AboutUs />
    <Reviews />
    <Services />
    <OperatingLocations />
    <FleetForYourCharter />
    <FutureProjects />
    <PhotoGallery />
    <AnnouncementModal
      onLearnMore={() => {
        navigate("/chardham-by-helicopter");
      }}
    />
  </div>
);
